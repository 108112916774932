<template>
<div id="Hours" class="py-8">
  <v-container class="text-center">
    <p class="mainHead">We're ready when you are</p>
    <v-img :src="require('../assets/open24-7.png')" class="mx-auto" contain max-width="512"></v-img>
    <p class="mainHead">With hours that work for anyone the only thing left to do is to stop by!</p>
  </v-container>
</div>
</template>

<script>
export default {
  name: "Hours"
}
</script>

<style scoped>
.mainHead {
  font-family: "Bebas Neue", Roboto, sans-serif;
  text-transform: uppercase;
  font-size: 3.5rem;
  color: #08234E;
}
</style>