import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.use(VueReCaptcha, { siteKey: '6LeGuuoiAAAAAFM38g_od70VskZZLFpkJqecUe4K' })
Vue.use(VueSweetalert2);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
