<template>
  <footer>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h3>Contact Us</h3>
          <p>
            3113 Airway Ave<br />
            Kingman, AZ 86409<br />
            <a href="tel:+19283773126">(928) 377-3126</a><br />
            <a href="mailto:airwaycarwashroute66@gmail.com">airwaycarwashroute66@gmail.com</a>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-img :src="require('../assets/footer_logo.png')" contain max-width="256" class="mx-auto mx-md-0 ms-md-auto"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
h3 {
  font-family: "Bebas Neue", Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
}

  footer {
    color: #08234E;
    margin-top: 8px;
  }
</style>