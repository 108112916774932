<template>
  <div id="ContactSection">
    <div class="bg py-12">
      <v-container>
        <h2 class="text-end">Contact Us</h2>
        <div class="form-area py-8">
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" id="Location" class="text-center py-8">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1937.9341260996148!2d-114.00698180295029!3d35.2262984451965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80cddc5d7de7ac4f%3A0xdb62b25b28c42c81!2sAirway%20Avenue%20Car%20Wash!5e0!3m2!1sen!2sus!4v1667880307344!5m2!1sen!2sus"
                  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
            </v-col>
            <v-col cols="12" md="6" id="ContactUs" class="text-center">
              <ContactForm class="px-12"></ContactForm>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
export default {
  name: "Contact",
  components: {ContactForm}
}
</script>

<style scoped>
#ContactSection {
  margin: 8px 0;
  padding: 8px 0;
  border-top: 8px solid #08234E;
  border-bottom: 8px solid #08234E;
}

.bg {
  background-color: #21bcff;
}

h2 {
  font-family: "Bebas Neue", Roboto, sans-serif;
  font-size: 3rem;
  color: white;
  font-weight: normal;
}

.form-area {
  background-color: white;
  padding: 16px;
  margin: 8px 0 16px;
}

#Location {
  border-right: 4px solid #21bcff;
}

</style>