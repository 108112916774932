<template>
  <div id="amenities" class="pb-10">
    <v-container class="text-center">
      <v-row justify="center" class="mb-12">
        <v-col cols="4">
          <v-img :src="require('../assets/tier2_pic1.png')" contain max-width="480"></v-img>
        </v-col>
        <v-col cols="4">
          <v-img :src="require('../assets/tier2_pic2.png')" contain max-width="480"></v-img>
        </v-col>
        <v-col cols="4">
          <v-img :src="require('../assets/tier2_pic3.png')" contain max-width="480"></v-img>
        </v-col>
      </v-row>
      <p class="mainHead">We offer 1 automatic bay &bullet; 1 RV/large vehicle bay with 12'6" clearance &bullet; 3 self service car/motorcycle bays</p>
      <p class="subHead">
        SOME OF OUR OTHER AMENITIES INCLUDE<br />
        vending machines &bullet; 6 vacuum stations &bullet; change machine
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Amenities"
}
</script>

<style scoped>
#amenities {
  background-color: #21bcff;
  font-family: Arial, sans-serif;
  color: white;
}

.row {
  margin-top: -96px;
}

.subHead {
  font-family: Arial, sans-serif;
  font-size: 2rem;
}

.mainHead {
  font-family: "Bebas Neue", Roboto, sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
}
</style>