<template>
  <div id="autoBay" class="pt-10">
    <v-container class="text-center">
      <p class="subHead">Want a break from the heat?</p>
      <p class="mainHead">Visit Our Automatic Bay</p>
      <v-row justify="center">
        <v-col cols="4">
          <v-img :src="require('../assets/tier1_pic1.png')" contain max-width="480"></v-img>
        </v-col>
        <v-col cols="4">
          <v-img :src="require('../assets/tier1_pic2.png')" contain max-width="480"></v-img>
        </v-col>
        <v-col cols="4">
          <v-img :src="require('../assets/tier1_pic3.png')" contain max-width="480"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AutoBay"
}
</script>

<style scoped>
  #autoBay {
    background-color: #21bcff;
    font-family: Arial, sans-serif;
    color: white;
  }

  .row {
    margin-bottom: -96px;
  }

  .mainHead,.subHead {
  }

  .subHead {
    font-family: Arial, sans-serif;
    font-size: 2rem;
  }

  .mainHead {
    font-family: "Bebas Neue", Roboto, sans-serif;
    text-transform: uppercase;
    font-size: 3.5rem;
  }
</style>