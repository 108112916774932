<template>
  <div class="SpacerBar"></div>
</template>

<script>
export default {
  name: "SpacerBar"
}
</script>

<style scoped>
  .SpacerBar {
    background-color: #08234E;
    height: 64px;
    margin: 8px 0;
  }
</style>