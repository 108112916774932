import { render, staticRenderFns } from "./Spacer.vue?vue&type=template&id=3356c766&scoped=true&"
import script from "./Spacer.vue?vue&type=script&lang=js&"
export * from "./Spacer.vue?vue&type=script&lang=js&"
import style0 from "./Spacer.vue?vue&type=style&index=0&id=3356c766&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3356c766",
  null
  
)

export default component.exports