<template>
  <v-app>
    <Header></Header>
    <SpacerBar></SpacerBar>
    <v-main>
      <AutoBay></AutoBay>
      <SelfServe></SelfServe>
      <Amenities></Amenities>
      <Platinum></Platinum>
      <Hours></Hours>
      <Contact></Contact>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import SpacerBar from "@/components/Spacer";
import AutoBay from "@/components/AutoBay";
import SelfServe from "@/components/SelfServe";
import Amenities from "@/components/Amenities";
import Platinum from "@/components/Platinum";
import Hours from "@/components/Hours";
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";

export default {
  name: 'App',

  components: {
    Footer,
    Contact,
    Hours,
    Platinum,
    Amenities,
    SelfServe,
    AutoBay,
    SpacerBar,
    Header
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.swal2-container {
  font-family: Arial, sans-serif;
}

.swal2-title {
  font-family: "Bebas Neue", Roboto, sans-serif;
  color: #08234E;
  text-transform: uppercase;
}
</style>
