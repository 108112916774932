<template>
  <div id="selfService">
    <v-container class="text-center">
      <p class="subHead">More of the do it yourself type?</p>
      <p class="mainHead">Visit one of our self service bays</p>
    </v-container>

  </div>
</template>

<script>
export default {
  name: "SelfServe"
}
</script>

<style scoped>
#selfService {
  color: #08234E;
  margin: 128px 0;
}
.subHead {
  font-family: Arial, sans-serif;
  font-size: 2rem;
}

.mainHead {
  font-family: "Bebas Neue", Roboto, sans-serif;
  text-transform: uppercase;
  font-size: 3.5rem;
}
</style>