<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field v-model="name" :counter="48" :rules="nameRules" label="Your Name" required></v-text-field>
    <v-text-field v-model="email" :rules="emailRules" label="Email Address" required></v-text-field>
    <v-textarea v-model="message" :rules="messageRules" :counter="1024" label="Your Comments"></v-textarea>
    <v-btn :disabled="!valid || sending" color="#21BCFF" @click="validate">Send Message</v-btn>
  </v-form>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactForm",
  methods: {
    async validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.sending = true;
        await this.$recaptchaLoaded()

        const token = await this.$recaptcha('sendMessage')
        console.log("Did we get a token?", token);
        axios.post('https://airwaycarwash.com/send/', {
          token: token,
          name: this.name,
          email: this.email,
          message: this.message
        }).then(() => {
          this.$swal.fire({
            title: 'Sent',
            text: 'Your message has been sent! Thank you!',
            icon: 'success',
            confirmButtonText: 'Thanks',
            confirmButtonColor: '#21BCFF'
          });
          this.$refs.form.reset();
          this.sending = false;
        }).catch(() => {
          this.$swal.fire({
            title: 'Error',
            text: 'There was an error sending your message!',
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: '#21BCFF'
          });
          this.sending = false;
        });
      }

    }
  },
  data: () => ({
    sending: false,
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'You must provide your name',
      v => (v && v.length <= 24) || 'Name must be less than 48 characters'
    ],
    email: '',
    emailRules: [
      v => !!v || "You must provide your email address",
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    message: '',
    messageRules: [
        v => !!v || 'You must provide a comment',
        v => (v && v.length <= 1024) || 'Message must be less than 1,024 characters'
    ]
  })
}

</script>

<style scoped>

</style>