<template>
  <header>
    <v-container>
      <v-row justify="center" align="center" align-content="center">
        <v-col cols="12" md="6" class="text-center ">
          <v-img alt="Airway Carwash" class="shrink mx-auto mx-md-0 me-md-auto" contain :src="require('../assets/logo.png')"
                 transition="scale-transition" max-width="256"/>
        </v-col>
        <v-col cols="12" md="6" class="text-center text-md-end" align-self="end">
          <v-img alt="Autowash Open 24 Hours!" class="shrink mb-4 mx-auto mx-md-0 ms-md-auto" contain
                 :src="require('../assets/autowash-24hours.png')" transition="scale-transition" width="170"/>
          <v-btn class="mr-2" text color="#08234E" href="#ContactUs">
            <v-icon class="mr-2">fa-envelope</v-icon>
            Contact Us
          </v-btn>
          <v-btn class="mr-2" text color="#08234E" href="#Location">
            <v-icon class="mr-2">fa-map-marker-alt</v-icon>
            Our Location
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
  header {
    border-bottom: 8px solid #08234E;
  }
</style>