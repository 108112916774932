<template>
<div id="platinum">
  <v-container>
    <v-img :src="require('../assets/platinum.jpg')" contain></v-img>
  </v-container>
</div>
</template>

<script>
export default {
  name: "Platinum"
}
</script>

<style scoped>

#platinum {
  margin: 8px 0;
  border-top: 8px solid #08234E;
  border-bottom: 8px solid #08234E;
  padding: 8px 0;
}

</style>